import type { RouteLocationNormalized } from 'vue-router';

type HasMerchantSlug<T extends RouteLocationNormalized> =
  T extends { params: { merchantSlug: string } } ? string : string | undefined;

export const useMerchantSlug = <T extends RouteLocationNormalized>(route: T) => {
	const merchantSlug = computed(() => {
		return 'merchantSlug' in route.params
			? String(route.params.merchantSlug)
			: undefined;
	}) as ComputedRef<HasMerchantSlug<T>>;

	return merchantSlug;
};
