<script setup lang="ts">
import type { ColorScheme } from './models/ColorScheme';
import { hexToRgb } from './utils';

const route = useRoute();

const merchantSlug = useMerchantSlug(route);

const { data: merchant } = useMerchantBySlugQuery({ merchantSlug });

const appConfig = useAppConfig();

watch(merchant, (_merchant) => {
	appConfig.ui.primary = _merchant ? 'brand' : 'amber';
}, { immediate: true });

const brandColorsStyle = computed(() => {
	if (!merchant.value) return [];

	const hexValues = merchant.value.colorScheme as ColorScheme;
	const rgbValues = Object.entries(hexValues).map(([key, value]) => {
		const rgb = hexToRgb(value);
		if (!rgb) return [key, value];
		return [key, `${rgb.r} ${rgb.g} ${rgb.b}`];
	});

	const cssVariables = rgbValues
		.map(([key, value]) => `--color-brand-${key}: ${value};`)
		.join('\n');

	return [{ id: 'brand-colors', innerHTML: () => `:root { ${cssVariables} }` }];
});

useHead({
	titleTemplate: (titleChunk) => {
		return titleChunk ? `${titleChunk} - Clickfire` : 'Clickfire';
	},
	meta: [
		{
			// Chrome, Firefox OS and Opera
			name: 'theme-color',
			content: '#f59e0b',
		},
		{
			// Windows Phone
			name: 'msapplication-navbutton-color',
			content: '#f59e0b',
		},
		{
			name: 'apple-mobile-web-app-capable',
			content: 'yes',
		},
		{
			name: 'apple-mobile-web-app-status-bar-style',
			content: 'black-translucent',
		},
	],
	style: brandColorsStyle,
});
</script>

<template>
	<div
		:class="{
			'h-full': route.meta.layout === 'blank' || 'default',
		}"
	>
		<NuxtLayout>
			<NuxtPage />
		</NuxtLayout>
		<UNotifications />
		<UModals />
	</div>
</template>
