import revive_payload_client_10424L0ZkO from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__mtcfn3qndghi5udszdsosdkfwe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_el41dw2p9k from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__mtcfn3qndghi5udszdsosdkfwe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_hjgAjx4v1P from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__mtcfn3qndghi5udszdsosdkfwe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_tzmH66vnPZ from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.4.1/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_gAG2xUTTGv from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__mtcfn3qndghi5udszdsosdkfwe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_D6vmb455lo from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__mtcfn3qndghi5udszdsosdkfwe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_QJFhwxtNle from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__mtcfn3qndghi5udszdsosdkfwe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_i4viCVGL9E from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__mtcfn3qndghi5udszdsosdkfwe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_9QAsZJjBt5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__mtcfn3qndghi5udszdsosdkfwe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_EbELCf3CCh from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0GX2wNXaPc from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_gTrXlhh6td from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_change-case@5.4.4_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5__b32ipjaglvb63xfisphjrkurua/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_kgds2ZTKIs from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_change-case@5.4.4_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5__b32ipjaglvb63xfisphjrkurua/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_kXOW5yVWnT from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.18.7_change-case@5.4.4_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5__b32ipjaglvb63xfisphjrkurua/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_xyy6tkAUk1 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.24.0_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_UMJ7yVs80Q from "/opt/build/repo/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.5_terser@5.34.1__vu_ckkdg35dmwgcrcmx2e7ehbh6tm/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import api_client_hHF76B0Hrp from "/opt/build/repo/plugins/api.client.ts";
import i18n_VfGcjrvSkj from "/opt/build/repo/plugins/i18n.ts";
import sentry_client_shVUlIjFLk from "/opt/build/repo/plugins/sentry.client.ts";
import userApiMapping_client_vfoioqdINo from "/opt/build/repo/plugins/userApiMapping.client.ts";
import userLocaleMapping_client_db1Wddiegg from "/opt/build/repo/plugins/userLocaleMapping.client.ts";
import vue_query_wrmMkNpEpe from "/opt/build/repo/plugins/vue-query.ts";
import zod_q7cfI0sipZ from "/opt/build/repo/plugins/zod.ts";
export default [
  revive_payload_client_10424L0ZkO,
  unhead_el41dw2p9k,
  router_hjgAjx4v1P,
  supabase_client_tzmH66vnPZ,
  payload_client_gAG2xUTTGv,
  navigation_repaint_client_D6vmb455lo,
  check_outdated_build_client_QJFhwxtNle,
  chunk_reload_client_i4viCVGL9E,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9QAsZJjBt5,
  switch_locale_path_ssr_EbELCf3CCh,
  i18n_0GX2wNXaPc,
  slideovers_gTrXlhh6td,
  modals_kgds2ZTKIs,
  colors_kXOW5yVWnT,
  plugin_client_xyy6tkAUk1,
  plugin_UMJ7yVs80Q,
  api_client_hHF76B0Hrp,
  i18n_VfGcjrvSkj,
  sentry_client_shVUlIjFLk,
  userApiMapping_client_vfoioqdINo,
  userLocaleMapping_client_db1Wddiegg,
  vue_query_wrmMkNpEpe,
  zod_q7cfI0sipZ
]