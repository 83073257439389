import { useQuery, useQueryClient } from '@tanstack/vue-query';
import type { Merchant } from '~/models/Merchant';

export type MerchantBySlugSearchParams = {
	merchantSlug: MaybeRef<string | undefined>;
};

export const useMerchantBySlugQuery = (
	options: MerchantBySlugSearchParams,
) => {
	const { $api } = useNuxtApp();
	const queryClient = useQueryClient();
	const query = useQuery({
		queryKey: ['public-merchant', options],
		queryFn: () => $api.GET(`/merchants/{slug}`, {
			params: {
				path: { slug: unref(options.merchantSlug)! },
			},
		}).then(unwrapResponse),
		initialData: () => queryClient.getQueryData<Merchant[]>(['merchants'])
			?.find((c) => c?.id === unref(options.merchantSlug)),
		initialDataUpdatedAt: () =>
			queryClient.getQueryState<Merchant[]>(['merchants'])
				?.dataUpdatedAt,
		enabled: computed(() => !!unref(options.merchantSlug)),
	});

	return query;
};
