export default defineAppConfig({
	ui: {
		primary: 'amber',
		container: {
			constrained: 'max-w-[1536px] w-full',
			padding: 'px-4 sm:px-6 lg:px-14',
		},
		button: {
			base: 'disabled:opacity-50',
		},
	},
});
