import { userProfileSchema, type UserProfile } from '~/models/UserProfile';

/**
 * We need to ensure that every user has a valid profile.
 * With this middleware we check if the user has a profile
 * and cache it on success. Otherwise we try to get the
 * profile data from their user_metadata and set it automatically.
 * If this fails, we redirect the user to a view where they
 * can fill out their profile data manually
 */

export default defineNuxtRouteMiddleware(async (to) => {
	const user = useSupabaseUser();

	if (!user.value) {
		return;
	}

	if (to.path === '/v1signin') {
		return;
	}

	const profileState = useState<UserProfile | undefined>('profile');

	if (profileState.value?.id !== user.value.id) {
		profileState.value = undefined;
	}

	if (profileState.value) {
		return;
	}

	const { $api } = useNuxtApp();

	try {
		const { data: profile } = await $api.GET('/me');

		if (profile) {
			profileState.value = profile;
			return;
		}

		const parseResult = userProfileSchema.safeParse(user.value.user_metadata);

		if (!parseResult.success && to.name === 'merchantSlug-signup-profile') return;

		/**
         * If the user has no data in user_metadata, we need them to
         * manually fill them we we redirect them to the profile page
         */
		if (!parseResult.success) {
			return navigateTo({ name: 'merchantSlug-signup-profile', query: { redirectTo: to.fullPath } });
		}

		await $api.POST('/me', {
			body: parseResult.data,
		});
	} catch {
		if (to.name === 'merchantSlug-signup-profile') return;
		return navigateTo({ name: 'merchantSlug-signup-profile', query: { redirectTo: to.fullPath } });
	}
});
