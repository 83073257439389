import { useQuery } from '@tanstack/vue-query';

export type UseMerchantsQueryOptions = {
	enabled?: MaybeRef<boolean>;
};

export const useMerchantsQuery = (options?: UseMerchantsQueryOptions) => {
	const { $api } = useNuxtApp();

	const enabled = computed(() => unref(options?.enabled) ?? true);

	const query = useQuery({
		queryKey: ['merchants'],
		queryFn: () => $api.GET('/merchants')
			.then(unwrapResponse)
			.then((data) => data?.toSorted(sortAlphabeticallyBy('name'))),
		enabled,
	});

	return query;
};
