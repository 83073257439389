import { default as indexkMGTGx5KohMeta } from "/opt/build/repo/pages/[merchantSlug]/account/index.vue?macro=true";
import { default as passwordGfWwLUnz1pMeta } from "/opt/build/repo/pages/[merchantSlug]/account/password.vue?macro=true";
import { default as payment1jYV5udwMgMeta } from "/opt/build/repo/pages/[merchantSlug]/account/payment.vue?macro=true";
import { default as payoutn7KY3ERHNWMeta } from "/opt/build/repo/pages/[merchantSlug]/account/payout.vue?macro=true";
import { default as social_45media9iTWWSyUnXMeta } from "/opt/build/repo/pages/[merchantSlug]/account/social-media.vue?macro=true";
import { default as applyEjUMawbYbiMeta } from "/opt/build/repo/pages/[merchantSlug]/campaigns/[campaignId]/apply.vue?macro=true";
import { default as indextXew3oYWt8Meta } from "/opt/build/repo/pages/[merchantSlug]/campaigns/[campaignId]/index.vue?macro=true";
import { default as indexCfhZGhhdtCMeta } from "/opt/build/repo/pages/[merchantSlug]/campaigns/index.vue?macro=true";
import { default as dashboardarsQor9NReMeta } from "/opt/build/repo/pages/[merchantSlug]/dashboard.vue?macro=true";
import { default as indexAJiqTVSLNvMeta } from "/opt/build/repo/pages/[merchantSlug]/index.vue?macro=true";
import { default as index5bfBEp2nNAMeta } from "/opt/build/repo/pages/[merchantSlug]/payouts/index.vue?macro=true";
import { default as confirm0fBYYa9zSDMeta } from "/opt/build/repo/pages/[merchantSlug]/signup/confirm.vue?macro=true";
import { default as indexwK0UFZYCDmMeta } from "/opt/build/repo/pages/[merchantSlug]/signup/index.vue?macro=true";
import { default as profile7ji6ethIGFMeta } from "/opt/build/repo/pages/[merchantSlug]/signup/profile.vue?macro=true";
import { default as _91submissionId_93YlO8MLncijMeta } from "/opt/build/repo/pages/[merchantSlug]/submissions/[submissionId].vue?macro=true";
import { default as indexirq6PmqXLEMeta } from "/opt/build/repo/pages/[merchantSlug]/submissions/index.vue?macro=true";
import { default as affiliatespaHUx5nx6hMeta } from "/opt/build/repo/pages/admin/[merchantId]/affiliates.vue?macro=true";
import { default as indexcdPbvi2lFPMeta } from "/opt/build/repo/pages/admin/[merchantId]/campaigns/[campaignId]/index.vue?macro=true";
import { default as submissions6T7CSLHvVwMeta } from "/opt/build/repo/pages/admin/[merchantId]/campaigns/[campaignId]/submissions.vue?macro=true";
import { default as indexVeTPCnmOmtMeta } from "/opt/build/repo/pages/admin/[merchantId]/campaigns/index.vue?macro=true";
import { default as new7u3gIZLS4aMeta } from "/opt/build/repo/pages/admin/[merchantId]/campaigns/new.vue?macro=true";
import { default as index30he8ukydVMeta } from "/opt/build/repo/pages/admin/[merchantId]/index.vue?macro=true";
import { default as indexKyTATD8DMLMeta } from "/opt/build/repo/pages/admin/[merchantId]/payouts/index.vue?macro=true";
import { default as _91submissionId_93xgR0rUPyW3Meta } from "/opt/build/repo/pages/admin/[merchantId]/submissions/[submissionId].vue?macro=true";
import { default as index7qcPXZQ3fgMeta } from "/opt/build/repo/pages/admin/index.vue?macro=true";
import { default as confirm487kH8ZehdMeta } from "/opt/build/repo/pages/confirm.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as logoutlLSqGnk5PzMeta } from "/opt/build/repo/pages/logout.vue?macro=true";
import { default as new_45passwordFYkUQ9WOpMMeta } from "/opt/build/repo/pages/new-password.vue?macro=true";
import { default as reset_45password4iBG212Ge4Meta } from "/opt/build/repo/pages/reset-password.vue?macro=true";
import { default as v1redirectVmY2pwdMS3Meta } from "/opt/build/repo/pages/v1redirect.vue?macro=true";
import { default as v1signinz198P0JcB1Meta } from "/opt/build/repo/pages/v1signin.vue?macro=true";
import { default as component_45stubmRMvB5HUkGMeta } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__mtcfn3qndghi5udszdsosdkfwe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubmRMvB5HUkG } from "/opt/build/repo/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__mtcfn3qndghi5udszdsosdkfwe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account",
    path: "/:merchantSlug()/account",
    meta: indexkMGTGx5KohMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/account/index.vue")
  },
  {
    name: "merchantSlug-account-password",
    path: "/:merchantSlug()/account/password",
    meta: passwordGfWwLUnz1pMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/account/password.vue")
  },
  {
    name: "merchantSlug-account-payment",
    path: "/:merchantSlug()/account/payment",
    meta: payment1jYV5udwMgMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/account/payment.vue")
  },
  {
    name: "merchantSlug-account-payout",
    path: "/:merchantSlug()/account/payout",
    meta: payoutn7KY3ERHNWMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/account/payout.vue")
  },
  {
    name: "merchantSlug-account-social-media",
    path: "/:merchantSlug()/account/social-media",
    meta: social_45media9iTWWSyUnXMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/account/social-media.vue")
  },
  {
    name: "ApplyForCampaign",
    path: "/:merchantSlug()/campaigns/:campaignId()/apply",
    meta: applyEjUMawbYbiMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/campaigns/[campaignId]/apply.vue")
  },
  {
    name: "Campaign",
    path: "/:merchantSlug()/campaigns/:campaignId()",
    meta: indextXew3oYWt8Meta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/campaigns/[campaignId]/index.vue")
  },
  {
    name: "MerchantCampaigns",
    path: "/:merchantSlug()/campaigns",
    meta: indexCfhZGhhdtCMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/campaigns/index.vue")
  },
  {
    name: "MerchantDashboard",
    path: "/:merchantSlug()/dashboard",
    meta: dashboardarsQor9NReMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/dashboard.vue")
  },
  {
    name: "merchantSlug",
    path: "/:merchantSlug()",
    meta: indexAJiqTVSLNvMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/index.vue")
  },
  {
    name: "MerchantPayouts",
    path: "/:merchantSlug()/payouts",
    meta: index5bfBEp2nNAMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/payouts/index.vue")
  },
  {
    name: "merchantSlug-signup-confirm",
    path: "/:merchantSlug()/signup/confirm",
    meta: confirm0fBYYa9zSDMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/signup/confirm.vue")
  },
  {
    name: "merchantSlug-signup",
    path: "/:merchantSlug()/signup",
    meta: indexwK0UFZYCDmMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/signup/index.vue")
  },
  {
    name: "merchantSlug-signup-profile",
    path: "/:merchantSlug()/signup/profile",
    meta: profile7ji6ethIGFMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/signup/profile.vue")
  },
  {
    name: "Submission",
    path: "/:merchantSlug()/submissions/:submissionId()",
    meta: _91submissionId_93YlO8MLncijMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/submissions/[submissionId].vue")
  },
  {
    name: "MerchantSubmissions",
    path: "/:merchantSlug()/submissions",
    meta: indexirq6PmqXLEMeta || {},
    component: () => import("/opt/build/repo/pages/[merchantSlug]/submissions/index.vue")
  },
  {
    name: "admin-merchantId-affiliates",
    path: "/admin/:merchantId()/affiliates",
    meta: affiliatespaHUx5nx6hMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/affiliates.vue")
  },
  {
    name: "admin-merchantId-campaigns-campaignId",
    path: "/admin/:merchantId()/campaigns/:campaignId()",
    meta: indexcdPbvi2lFPMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/campaigns/[campaignId]/index.vue")
  },
  {
    name: "admin-merchantId-campaigns-campaignId-submissions",
    path: "/admin/:merchantId()/campaigns/:campaignId()/submissions",
    meta: submissions6T7CSLHvVwMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/campaigns/[campaignId]/submissions.vue")
  },
  {
    name: "admin-merchantId-campaigns",
    path: "/admin/:merchantId()/campaigns",
    meta: indexVeTPCnmOmtMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/campaigns/index.vue")
  },
  {
    name: "admin-merchantId-campaigns-new",
    path: "/admin/:merchantId()/campaigns/new",
    meta: new7u3gIZLS4aMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/campaigns/new.vue")
  },
  {
    name: "AdminMerchantDashboard",
    path: "/admin/:merchantId()",
    meta: index30he8ukydVMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/index.vue")
  },
  {
    name: "admin-merchantId-payouts",
    path: "/admin/:merchantId()/payouts",
    meta: indexKyTATD8DMLMeta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/payouts/index.vue")
  },
  {
    name: "admin-merchantId-submissions-submissionId",
    path: "/admin/:merchantId()/submissions/:submissionId()",
    meta: _91submissionId_93xgR0rUPyW3Meta || {},
    component: () => import("/opt/build/repo/pages/admin/[merchantId]/submissions/[submissionId].vue")
  },
  {
    name: "AdminMerchantSelection",
    path: "/admin",
    meta: index7qcPXZQ3fgMeta || {},
    component: () => import("/opt/build/repo/pages/admin/index.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    component: () => import("/opt/build/repo/pages/confirm.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginBXD9sq3T3sMeta || {},
    component: () => import("/opt/build/repo/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/opt/build/repo/pages/logout.vue")
  },
  {
    name: "new-password",
    path: "/new-password",
    meta: new_45passwordFYkUQ9WOpMMeta || {},
    component: () => import("/opt/build/repo/pages/new-password.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45password4iBG212Ge4Meta || {},
    component: () => import("/opt/build/repo/pages/reset-password.vue")
  },
  {
    name: "v1redirect",
    path: "/v1redirect",
    meta: v1redirectVmY2pwdMS3Meta || {},
    component: () => import("/opt/build/repo/pages/v1redirect.vue")
  },
  {
    name: "v1signin",
    path: "/v1signin",
    component: () => import("/opt/build/repo/pages/v1signin.vue")
  },
  {
    name: component_45stubmRMvB5HUkGMeta?.name,
    path: "/admin/login",
    component: component_45stubmRMvB5HUkG
  }
]