import { DateTime } from 'luxon';
import type { CamelToSnakeCaseKeys, SnakeToCamelCaseKeys } from '~/types/Types';

export const notEmpty = <TValue>(value: TValue | null | undefined): value is TValue => {
	return value !== null && value !== undefined;
};

export const insertIf = <T>(condition: boolean | string | undefined, ...elements: T[]) => {
	return condition ? elements : [];
};

export const getDateFromDateString = (dateString: string) => {
	const match = dateString.match(/\d+/);

	if (!match) {
		return null;
	}

	const seconds = parseInt(match[0]) / 1000;

	return DateTime.fromSeconds(seconds);
};

export const snakeToCamel = (input: string): string => {
	return input.toLowerCase()
		.replace(/(_\w)/g, (m) => m.toUpperCase()
			.substring(1));
};

export const camelToSnake = (s: string): string =>
	s.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);

export const convertSnakeToCamel = <T extends Record<string, unknown>>(obj: T): SnakeToCamelCaseKeys<T> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: any = {};

	for (const key in obj) {
		result[snakeToCamel(key)] = obj[key];
	}

	return result as SnakeToCamelCaseKeys<T>;
};

export const convertCamelToSnake = <T extends Record<string, unknown>>(obj: T): CamelToSnakeCaseKeys<T> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: any = {};

	for (const key in obj) {
		result[camelToSnake(key)] = obj[key];
	}

	return result as CamelToSnakeCaseKeys<T>;
};

export const withoutEmptyProperties = <T extends object>(input: T) => {
	return Object.entries(input).reduce((copy, [key, value]) => {
		if (!value) {
			return copy;
		}

		if (typeof value === 'object') {
			if (Object.keys(value).length < 1) {
				return copy;
			}

			if (Object.values(value).every((v) => !v)) {
				return copy;
			}
		}

		return {
			...copy,
			[key]: value,
		};
	}, {} as T);
};

export const sortAlphabeticallyBy = <T extends object>(key: keyof T, ascending = false) => {
	return (a: T, b: T): number => {
		const stringA = String(a[key]).toLocaleLowerCase();
		const stringB = String(b[key]).toLocaleLowerCase();

		if (stringA < stringB) return ascending ? 1 : -1;
		if (stringA > stringB) return ascending ? -1 : 1;

		return 0;
	};
};

export const sortNumberBy = <T extends object>(key: keyof T, ascending = false) => {
	return (a: T, b: T): number => {
		const aValue = a[key] as unknown as number;
		const bValue = b[key] as unknown as number;

		return ascending ? aValue - bValue : bValue - aValue;
	};
};

export const isTrueValue = (input: unknown) => {
	if (typeof input === 'boolean') {
		return input;
	}

	if (typeof input === 'string') {
		return input.toLowerCase() === 'true';
	}

	return false;
};

export const ensureArray = <T>(input: T[] | undefined | null): T[] => {
	return input ?? [];
};

export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
	array.reduce((acc, value, index, _array) => {
		(acc[predicate(value, index, _array)] ||= []).push(value);
		return acc;
	}, {} as { [key: string]: T[] });

export const toKebabCase = (input: string) => {
	return input.replace(/([a-z])([A-Z])/g, '$1-$2')
		.toLowerCase()
		.split(' ')
		.join('-');
};

export const hexToRgb = (hex: string) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1] || '0', 16),
				g: parseInt(result[2] || '0', 16),
				b: parseInt(result[3] || '0', 16),
			}
		: null;
};
