export default defineNuxtRouteMiddleware(async (to) => {
	const merchantSlug = useMerchantSlug(to);
	const user = useSupabaseUser();

	if (!merchantSlug.value) {
		return;
	}

	const isMerchantsQueryEnabled = ref(!!user.value);

	const { suspense: merchantSuspense } = useMerchantBySlugQuery({ merchantSlug });
	const { suspense: userMerchantsSuspense } = useMerchantsQuery({ enabled: isMerchantsQueryEnabled });

	const { data: merchant } = await merchantSuspense();

	if (!merchant) {
		return navigateTo('/');
	}

	if (to.meta.isPublicMerchantPage) {
		return;
	}

	isMerchantsQueryEnabled.value = true;

	const { data: userMerchants } = await userMerchantsSuspense();

	const hasAccessToMerchant = !!userMerchants?.find((m) => m.id === merchant.id);

	if (!hasAccessToMerchant) {
		return navigateTo('/');
	}
});
